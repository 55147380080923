import { axiosAuth } from "./api";

export default {
  visitType: (id: number) =>
    axiosAuth.delete<void>(`/therapist_visit_types/${id}/`),
  therapistOffice: (officeId: number) =>
    axiosAuth.delete<void>(`/therapist_offices/${officeId}/`),
  therapistSpan: (id: number) =>
    axiosAuth.delete<void>(`/my_available_spans/${id}/`),
  renewableSpan: (id: number) => 
    axiosAuth.delete<void>(`/renewable_spans/${id}/`)
};
