export function getBrowserLocales(options = {}) {
  const defaultOptions = { countryCodeOnly: false };

  const opt = { ...defaultOptions, ...options };

  const navigatorLocales =
    navigator.languages !== undefined
      ? navigator.languages
      : [navigator.language];

  return opt.countryCodeOnly
    ? navigatorLocales.map((x) => x.trim().split(/-|_/)[0])
    : navigatorLocales.map((x) => x.trim());
}
