import Axios, { AxiosRequestConfig } from "axios";
import router from "@/router";

const axios = Axios.create({ baseURL: process.env.VUE_APP_TARGET + "/api" });

const axiosAuth = Axios.create({
  baseURL: process.env.VUE_APP_TARGET + "/api",
});

function responseHanlder(res: any) {
  return res;
}

function errorHandler(err: any) {
  if (err.response.status === 401 || err.response.status === 403) {
    if (router.currentRoute.path !== "/signin")
      router.push({
        path: "/signin",
        query: { redirect: router.currentRoute.fullPath },
      });
  }
  return Promise.reject(err);
}

axiosAuth.interceptors.request.use((config: AxiosRequestConfig) => {
  if (config.method === "get" || config.method === "delete") {
    config.params = {
      ...config.params,
      token: localStorage.getItem("token"),
    };
  } else {
    if (config.data.append)
      config.data.append("token", localStorage.getItem("token"));
    else config.data.token = localStorage.getItem("token");
  }

  return config;
});

axiosAuth.interceptors.response.use(responseHanlder, errorHandler);

axios.interceptors.response.use(responseHanlder, errorHandler);

export { axios, axiosAuth };
