import { axios, axiosAuth } from "./api";
import { Visit, Therapist } from "@/interfaces/index";
import {
  RegularSessionPayment,
  PackageSessionPayment,
  PurchaseChallenge,
} from "../interfaces/payment";
import { MoodScore, SessionNote } from "@/interfaces/therapy";
import { User } from "@/interfaces/user";

export default {
  visitType: (visitType: Visit.VisitType) =>
    axiosAuth.post("/therapist_visit_types/", visitType),
  cancelSession: (sessionId: number) =>
    axiosAuth.post(`/sessions/${sessionId}/cancel/`, {
      confirm_cancellation: true,
    }),
  acceptSession: (sessionId: number) =>
    axiosAuth.post(`/sessions/${sessionId}/accept/`, {}),
  therapistOffice: (office: Therapist.TherapistOffice) =>
    axiosAuth.post(`/therapist_offices/`, office),
  completeEpisode: (challenge_pk: number, episode_pk: number) =>
    axiosAuth.post(
      `/challenges/${challenge_pk}/episodes/${episode_pk}/complete/`,
      {}
    ),
  createTherapistRenewableSpan: (body: {}) =>
    axiosAuth.post("/renewable_spans/", body),
  createTherapistSpan: (body: {}) =>
    axiosAuth.post("/my_available_spans/", body),
  billingInfo: (data: object) => axiosAuth.post("/tpay_account/", data),
  newsletterSignUp: (email: string, captcha_token: string) =>
    axiosAuth.post("/newsletter/signup/", {
      email: email,
      captcha_token: captcha_token,
    }),
  sendSurvey: (time: number, value: number, sign: number) =>
    axiosAuth.post("/update_survey/", {
      time,
      value,
      sign,
    }),
  moodScores: (data_type: string, time_period: number) =>
    axiosAuth.post<MoodScore[]>("/get_data/", { data_type, time_period }),
  sendMessage: (therapy: number, text: string) =>
    axiosAuth.post("/messages/", { therapy, text }),
  sendBotMessage: (url: string, sender: User, message: string, token: string) =>
    axios.post(
      url,
      {
        sender: `${sender.first_name} ${sender.last_name}`,
        message,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    ),
  therapistPhoto: (image: File) => {
    const formData = new FormData();
    formData.append("photo", image, image.name);
    return axiosAuth.post("/therapist_photo/", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  sessionNotes: (note: SessionNote) =>
    axiosAuth.post<any>(`/session_notes/`, note),
  bookSession: (booking_info: RegularSessionPayment | PackageSessionPayment) =>
    axiosAuth.post<{ redirection_link: string }>(
      "/book_session/",
      booking_info
    ),
  purchaseChallenge: (challenge_info: PurchaseChallenge) =>
    axiosAuth.post<{ redirection_link: string }>(
      "/challenge_purchase/",
      challenge_info
    ),
  passwordResetRequest: (payload: any) => {
    axios.post<any>(
      "/password_reset/request/", payload
    )
  },
  resetPassword: (payload: any) => {
    axios.post<any>(
      "/password_reset/confirm/", payload
    )
  }
};
