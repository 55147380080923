import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import store from "@/store";
import landingPageRoutes from "./landingpage";
import dashboardRoutes from "./dashboard";

const LandingPage = () => import("@/views/LandingPage.vue");
const Dashboard = () => import("@/views/Dashboard.vue");
const TermsAndConditions = () =>
  import("@/views/landing-page/TermsAndConditions.vue");

Vue.use(VueRouter);

const mainRoutes: Array<RouteConfig> = [
  { path: "/", component: LandingPage, children: landingPageRoutes },
  { path: "/dashboard/", component: Dashboard, children: dashboardRoutes },
  { path: "/termsandconditions", component: TermsAndConditions },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: mainRoutes,
  scrollBehavior: (to, _, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return {
        selector: to.hash,
      };
    } else {
      return { x: 0, y: 0 };
    }
  },
});

const getPageForStage = (stage: number) => {
  switch (stage) {
    case 0:
      return "/dashboard/myprofile/personal";
    case 1:
      return "/dashboard/myprofile/background";
    case 2:
      return "/dashboard/myprofile/office";
    case 3:
      return "/dashboard/myprofile/availability";
    case 4:
      return "/dashboard/myprofile/billing";
    case 5:
      return "/dashboard/myprofile/billing";
    default:
      return "/dashboard/myprofile/personal";
  }
};

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const requiresInfoFilling = to.matched.some(
    (record) => record.meta.requiredInfoFilling
  );

  const challengePayment = to.matched.some(
    (record) => record.meta.challengePayment
  );
  if (challengePayment) {
    next({ query: { redirect: "challenge-payment" } });
    return;
  }

  const requiresStage = to.matched.some((record) => record.meta.stage);
  if (to.fullPath.startsWith("/login")) {
    next({ path: "/dashboard" });
  }
  if (requiresAuth && !store.state.user) {
    await store.dispatch("login");
    if (store.state.user) {
      if (
        (requiresInfoFilling &&
          to.meta.requiredInfoFilling &&
          !store.state.isFilled &&
          to.path !== getPageForStage(store.state.stage || 0)) ||
        (!store.state.isFilled &&
          requiresStage &&
          store.state.stage !== null &&
          to.meta.stage > store.state.stage)
      ) {
        next({
          path: getPageForStage(store.state.stage || 0),
          query: { redirect: to.path },
        });
      }
      next();
    } else {
      next({ path: "/login", query: { redirect: to.path } });
    }
  } else {
    if (
      requiresInfoFilling &&
      to.meta.requiredInfoFilling &&
      !store.state.isFilled &&
      to.path !== getPageForStage(store.state.stage || 0)
    )
      next({
        path: getPageForStage(store.state.stage || 0),
        query: { redirect: to.path },
      });
    if (
      !store.state.isFilled &&
      store.state.stage !== null &&
      requiresStage &&
      to.meta.stage > store.state.stage
    )
      next({
        path: getPageForStage(store.state.stage || 0),
        query: { redirect: to.path },
      });
    next();
  }
});

export default router;
