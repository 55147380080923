<script type="ts">
import Vue from "@/interfaces/vue"
import { Component } from "vue-property-decorator";

@Component
export default class Spinner extends Vue {}
</script>

<template>
  <svg
    class="spinner"
    width="151px"
    height="151px"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
  >
    <circle
      cx="50"
      cy="50"
      fill="none"
      stroke="#589bf0"
      stroke-width="3"
      r="32"
      stroke-dasharray="131.94689145077132 45.982297150257104"
    >
      <animateTransform
        attributeName="transform"
        type="rotate"
        repeatCount="indefinite"
        dur="0.6s"
        values="0 50 50;360 50 50"
        keyTimes="0;1"
      ></animateTransform>
    </circle>
  </svg>
</template>

<style scoped lang="scss">
svg {
  margin: 40px auto;
  background: transparent none repeat scroll 0% 0%;
  display: block;
  shape-rendering: auto;
}
</style>
