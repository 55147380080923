import { auth, get } from "@/api";
import Vue from "vue";
import Vuex, { ActionTree, GetterTree, MutationTree } from "vuex";
import { State, Getters, Actions, Mutations } from "@/interfaces/store";
import { parseJwt } from "@/helpers/jwt";

Vue.use(Vuex);

const state: State = {
  user: null,
  dict: null,
  modal: null,
  payment: null,
  // challengePayment: null,
  calendar: {
    therapistGroups: null,
    therapistSpans: null,
  },
  visitInfo: null,
  stage: null,
  isFilled: false,
  chatbotToken: null,
  chatbotRefresh: null,
};

const getters: GetterTree<State, State> & Getters = {
  isLoggedIn({ user }) {
    return user !== undefined && user !== null;
  },
};

const actions: ActionTree<State, State> & Actions = {
  async login({ commit, dispatch }, payload) {
    try {
      if (payload) {
        const {
          data: { token },
        } = await auth.login(payload);
        localStorage.setItem("token", token);
        const { data } = await auth.chatbotToken(token);
        localStorage.setItem("chatbotToken", data.access);
        localStorage.setItem("chatbotRefresh", data.refresh);
      }
      await dispatch("refreshUser");
      return Promise.resolve();
    } catch (err) {
      localStorage.setItem("token", "");
      localStorage.setItem("chatbotToken", "");
      localStorage.setItem("chatbotRefresh", "");
      commit("setUser", null);
      return Promise.reject(err);
    }
  },
  async refreshChatbotToken({ commit, dispatch }) {
    try {
      if (localStorage.getItem("chatbotRefresh")) {
        const refreshToken = localStorage.getItem("chatbotRefresh")!;
        const jwt = parseJwt(refreshToken);
        if (jwt.exp <= (Date.now()/1000)) {
          const { data } = await auth.chatbotToken(localStorage.getItem("token") as string);
          localStorage.setItem("chatbotToken", data.access);
          localStorage.setItem("chatbotRefresh", data.refresh);
        } else {
          const { data } = await auth.chatbotRefresh(refreshToken);
          localStorage.setItem("chatbotToken", data.access);
        }
        await dispatch("refreshUser");
        return Promise.resolve();
      }
    } catch (err) {
      commit("setChatbotRefresh", undefined);
      Promise.reject(err);
    }
  },
  async refreshUser({ commit }) {
    try {
      if (localStorage.getItem("token")) {
        const { data: user } = await get.users();
        commit("setUser", user);
        commit("setChatbotToken", localStorage.getItem("chatbotToken"));
        commit("setChatbotRefresh", localStorage.getItem("chatbotRefresh"));
        if (user.is_therapist) {
          const { data } = await get.therapistInfo();
          commit("setFilled", data.completed_fillings === 5 && data.confirmed);
          commit("setStage", data.completed_fillings);
          return Promise.resolve();
        } else {
          commit("setFilled", true);
          return Promise.resolve();
        }
      }
    } catch (err) {
      commit("setUser", undefined);
      Promise.reject(err);
    }
  },
  async signup({ commit }, payload) {
    try {
      await auth.signup(payload);
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async getDict({ commit }) {
    try {
      const { data } = await get.dict();
      commit("setDict", data);
    } catch (err) {
      console.log(err);
    }
  },

  async getTherapistGroups({ commit }) {
    try {
      const { data } = await get.therapistDateGroups();
      commit("setTherapistGroups", data);
    } catch (err) {
      commit("setTherapistGroups", null);
    }
  },
};

const mutations: MutationTree<State> & Mutations = {
  setUser(state, user) {
    state.user = user;
  },
  setDict(state, dict) {
    state.dict = dict;
  },
  setModal(state, modal) {
    state.modal = modal;
  },
  setPayment(state, payment) {
    state.payment = payment;
  },
  setFilled(state, filled) {
    state.isFilled = filled;
  },
  setStage(state, stage) {
    state.stage = stage;
  },
  setTherapistSpans(state, list) {
    state.calendar.therapistSpans = list;
  },
  setTherapistGroups(state, groups) {
    state.calendar.therapistGroups = groups;
  },
  setChatbotToken(state, token) {
    state.chatbotToken = token;
  },
  setChatbotRefresh(state, token) {
    state.chatbotRefresh = token;
  },
};

export default new Vuex.Store({ state, getters, actions, mutations });
