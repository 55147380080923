import { axios } from "./api";

export default {
  login: (loginInfo: {
    username: string;
    password: string;
    captcha_token: string;
  }) =>
    axios.post<{ token: string }>("/auth/", {
      ...loginInfo,
      token_type: "selfissued",
    }),
  chatbotToken: (token: string) =>
    axios.post<{
      access: string;
      refresh: string;
    }>(
      "/external_token/",
      { token }
    ),
  chatbotRefresh: (refreshToken: string) =>
    axios.post<{ access: string }>("/external_token/refresh/", {
      refresh: refreshToken,
    }),
  signup: (payload: any) => axios.post<any>("/users/", payload),
  therapistSignup: (payload: any) =>
    axios.post<any>("/new_therapist/", payload),
};
