<script type="ts">
import Vue from "@/interfaces/vue";
import { Component } from "vue-property-decorator";
import { Fragment } from "vue-fragment";
import i18n from "@/i18n";
import "vueperslides/dist/vueperslides.css";
import "vue-select/dist/vue-select.css";


@Component({
  components: {
    Fragment,
  },
  metaInfo: {
    titleTemplate: "%s | Calmsie",
    htmlAttrs: {
      lang: i18n.locale
    },
    meta: [
      { name: "robots", content: "all" },
    ]
  }
})
export default class App extends Vue {
  created() {
    this.$store.dispatch("getDict");
    //this.$store.dispatch("login")
  }
}
</script>
<template>
  <fragment>
    <modal />
    <router-view />
  </fragment>
</template>

<style lang="scss">
@import "@/scss/variables.scss";

:root {
  font-size: 1rem;
  @media (max-width: 350px) {
    font-size: 0.9rem;
  }
}

:root {
  --main: #2572d6;
  --main-light: #589bf0;
  --blue: #a6ebff;
  --gray-light: #d3d3d3;
  --gray-very-light: #f2f2f2;
  --gray-middle: #aaaaaa;
  --gray-dark: #2f3841;
  --gray-visit: #72767a;
  --white: #ffffff;
  --main-blue: #2572d6;
  --grad-blue: #589bf0;
  --grad-blue-two: #b7d8f7;
  --foot-blue: #a3c6f4;
  --par-blue: #eef5fd;
  --dashboard-blue: #ecf2fb;
  --component-blue: #dbe7f8;
  --back-blue: #8abbf4;
  --text-gray: #72767a;
  --par-gray: #414950;
  --head-gray: #3a474e;
}

* {
  box-sizing: border-box;
  font-family: "Spartan", sans-serif;
  line-height: 1.65em;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
}

.main-column {
  display: flex;
  flex-direction: column;

  .button,
  .button-white {
    min-width: 180px;
  }

  @media (max-width: 600px) {
    .button,
    .button-white {
      min-width: 100px;
    }
  }
}

input {
  padding: 0.2rem 0.3rem;
  border: 0;
  border-bottom: 2px solid #00000049;
  outline: none;
  font-size: 1rem;
}

input:focus {
  border-color: $main-blue;
}

a {
  color: $main-blue;
}

.button,
.button-white {
  border: 1px solid $main-blue;
  border-radius: $small-border-radius;
  padding: 0.5rem;
  cursor: pointer;
  font-weight: bold;
}

.button a {
  text-decoration: none;
}

.button {
  background: $main-blue;
  color: white;

  &:hover {
    border-color: $main-blue-hover;
    background: $main-blue-hover;
  }

  * {
    color: white;
    text-decoration: none;
  }
}

.button-white {
  background: white;
  color: $text-color;
  text-decoration: none;

  &:hover {
    border-color: $main-blue-hover;
  }

  * {
    color: $text-color;
    text-decoration: none;
  }
}

.card,
.card-borderless {
  padding: 1.5rem;
  @media (max-width: 500px) {
    padding: 1rem;
  }
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.card {
  border-radius: 10px;
  @media (max-width: 500px) {
    border-radius: 8px;
  }
  box-shadow: 0px 0px 10px 5px $main-blue-shadow;
}

.card-wrapper {
  position: relative;
}

.vueperslides--fixed-height.vueperslides--bullets-outside {
  margin-bottom: 0;
}

.vueperslides__bullets {
  position: static;
}

@media (max-width: 550px) {
  .vueperslides__bullets {
    > * {
      margin: 0.5rem 0.4rem;
    } 
  }
}

@media (max-width: 440px) {
  .vueperslides__bullets {
    > * {
      margin: 0.5rem 0.2rem;
    }
  }
}

.vueperslides__arrow--prev {
  svg {
    width: 3.3em;
  }
  @media (min-width: 650px) {
    left: -3.5rem;
  }
  @media (max-width: 650px) {
    left: -2.2rem;
  }
  @media (max-width: 450px) {
    left: -2rem;
  }
}

.vueperslides__arrow--next {
  svg {
    width: 3.3em;
  }
  @media (min-width: 650px) {
    right: -3.5rem;
  }
  @media (max-width: 650px) {
    right: -2.2rem;
  }
  @media (max-width: 450px) {
    right: -2rem;
  }
}

.vueperslides__arrows {
  color: #161616;
}
@media (max-width: 1050px) {
  .mobile-hide {
    display: none;
  }
}

@media (max-width: 500px) {
  .vueperslides__arrow svg {
    width: 2.9em;
  }
}
.toasted-container.top-center {
  top: 60px !important;
}
</style>
