import { RouteConfig } from "vue-router";

const Home = () => import("@/views/landing-page/Home.vue");
const TherapistList = () => import("@/views/landing-page/TherapistList.vue");
const TherapistProfile = () =>
  import("@/views/landing-page/TherapistProfile.vue");
const Business = () => import("@/views/landing-page/Business.vue");
const Blog = () => import("@/views/landing-page/Blog.vue");
const Article = () => import("@/views/landing-page/Article.vue");
const About = () => import("@/views/landing-page/About.vue");
const ForIndividuals = () => import("@/views/landing-page/ForIndividuals.vue");
const SignIn = () => import("@/views/landing-page/auth/SignIn.vue");
const SignUp = () => import("@/views/landing-page/auth/SignUp.vue");
const TherapistSignIn = () =>
  import("@/views/landing-page/auth/TherapistSignIn.vue");
const TherapistSignUp = () =>
  import("@/views/landing-page/auth/TherapistSignUp.vue");
const DayChallenge = () =>
  import("@/views/landing-page/challenges/DayChallenge.vue");
const ChallengesOverview = () =>
  import("@/views/landing-page/challenges/ChallengesOverview.vue");
// const PaymentView = () => import("@/views/payments/PaymentView.vue");
const ChallengePayment = () => import("@/views/payments/ChallengePayment.vue");
const VisitPayment = () => import("@/views/payments/VisitPayment.vue");
const PaymentVisitSuccess = () => import("@/views/payments/PaymentSuccess.vue");
const PaymentVisitFailture = () =>
  import("@/views/payments/PaymentFailture.vue");
const PaymentChallengeSuccess = () =>
  import("@/views/payments/PaymentChallengeSuccess.vue");
const ChallengeAlreadyOwned = () =>
  import("@/views/payments/ChallengeAlreadyOwned.vue");
const ChallengeWaitingConfirm = () =>
  import("@/views/payments/ChallengeWaitingConfirm.vue");
const SignOut = () => import("@/views/landing-page/SignOut.vue");
const ForSpecialists = () => import("@/views/landing-page/ForSpecialists.vue");
const ForgotPassword = () => import("@/views/landing-page/password-reset/ForgotPassword.vue")
const ResetPassword = () => import("@/views/landing-page/password-reset/ResetPassword.vue")

const routes: RouteConfig[] = [
  { path: "", component: Home },
  { path: "therapists", component: TherapistList },
  { path: "therapist-profile/:id", component: TherapistProfile },
  { path: "business", component: Business },
  { path: "blog", component: Blog },
  { path: "article", component: Article },
  { path: "about", component: About },
  { path: "for-individuals", component: ForIndividuals },
  { path: "challenges/:challengeId", component: DayChallenge },
  { path: "challenges", component: ChallengesOverview },
  { path: "signin", component: SignIn },
  { path: "signup", component: SignUp },
  { path: "signin-therapist", component: TherapistSignIn },
  { path: "signup-therapist", component: TherapistSignUp },
  { path: "for-specialists", component: ForSpecialists },
  {
    path: "/payment-challenge",
    component: ChallengePayment,
    meta: { requiresAuth: true, challengePayment: true },
  },
  {
    path: "/payment-visit",
    component: VisitPayment,
    meta: { requiresAuth: true },
  },
  {
    path: "/payment/checkout/success",
    component: PaymentVisitSuccess,
    meta: { requiresAuth: true },
  },
  {
    path: "/payment/checkout/failure",
    component: PaymentVisitFailture,
    meta: { requiresAuth: true },
  },
  {
    path: "/payment/challenge/success",
    component: PaymentChallengeSuccess,
    meta: { requiresAuth: true },
  },
  {
    path: "/payment/challenge/owned",
    component: ChallengeAlreadyOwned,
    meta: { requiresAuth: true },
  },
  {
    path: "/payment/challenge/confirm",
    component: ChallengeWaitingConfirm,
    meta: { requiresAuth: true },
  },
  {
    path: "forgot-password",
    component: ForgotPassword
  },
  {
    path: "reset-password",
    component: ResetPassword
  },
  {
    path: "signout",
    component: SignOut,
  },
];
export default routes;
