import { User } from "@/interfaces";
import { Therapist } from "@/interfaces";
import { axiosAuth } from "./api";

export default {
  therapistInfo: (data: any) => {
  const formData = new FormData();
  const keys = Object.keys(data);
  for (const key of keys) {
    if (key === "certification") {
      if(data[key]) formData.append("certification", data[key], data[key].name);
      else formData.append("certification", "");
    } else if (key === "languages" || key === "courses" || key === "schools") {
      console.log(data[key]);
      for (let i=0; i<data[key].length; i++){
        formData.append(key, data[key][i]);
        console.log(data[key][i]);
      }
    } else {
      formData.append(key, data[key]);
    }
  }
  return axiosAuth.put<Therapist.TherapistInfo>("/therapist_info/", formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
},
  therapistUserInfo: (data: object) =>
    axiosAuth.put<User.UserInfo>("/user_info/", data),
  therapistOffice: (officeId: number, office: Therapist.TherapistOffice) =>
    axiosAuth.put(`/therapist_offices/${officeId}/`, office),
};
