import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import i18n from "@/i18n";
import Toasted from "vue-toasted";
import VueModal from "@/plugins/modal/modal";
import VueMeta from "vue-meta";
import Spinner from "@/components/Spinner.vue";
import { getStartingLocale, loadLanguage } from "./i18n-setup";

const prod = process.env.NODE_ENV === "production";
const shouldSW = "serviceWorker" in navigator && prod;
if (shouldSW) {
  if ("serviceWorker" in navigator) {
    // Use the window load event to keep the page load performant
    window.addEventListener("load", () => {
      navigator.serviceWorker.register("/service-worker.js");
    });
  }
}

Vue.use(Toasted, {
  position: "top-center",
  theme: "outline",
  duration: 5000,
  type: "error",
  className: "toasted",
});

Vue.use(VueModal, {
  store: new VueModal.Store({ open: false, config: null, functions: null }),
});

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
});

Vue.component("spinner", Spinner);

export { i18n };

loadLanguage(i18n, getStartingLocale()).then(() =>
  new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
  }).$mount("#app")
);
