import { axios, axiosAuth } from "./api";
import {
  AvailableDate,
  TherapistDates,
  TherapistVisitType,
} from "@/interfaces/visit";
import { User, UserInfo } from "@/interfaces/user";
import {
  SessionCommission,
  Settlement,
  Therapist,
  TherapistInfo,
  TherapistOffice,
} from "@/interfaces/therapist";
import { Dictionaries } from "@/interfaces/dictionary";
import {
  Message,
  Messages,
  MoodScore,
  Prescription,
  Review,
  Session,
  SessionNote,
  Therapy,
} from "@/interfaces/therapy";
import { LawAndForm, Bank, BillingData } from "@/interfaces/payment";
import { ChallengeShortInfo, ChallengeSeries } from "@/interfaces/challenge";
import { DateGroup } from "@/interfaces/misc";
import { Article } from "@/interfaces/blog";

export default {
  // --- USER ---
  user: () => axiosAuth.get<UserInfo>(`/user_info/`),
  users: () => axiosAuth.get<User>("/users/"),

  // --- THERAPIST INFO ---
  therapist: (therapistId: number) =>
    axios.get<Therapist>(`/therapists/${therapistId}/`),
  therapists: () => axios.get<Therapist[]>("/therapists/"),
  therapistInfo: () => axiosAuth.get<TherapistInfo>("/therapist_info/"),
  therapistPhoto: (filePath: string) => axiosAuth.get(filePath),
  therapistPhotoPath: () =>
    axiosAuth.get<{ photo: string }>(`/therapist_photo`),
  therapistReviews: (id: number) =>
    axios.get<Review[]>(`/therapists/${id}/reviews/`),
  therapistOffices: () =>
    axiosAuth.get<TherapistOffice[]>(`/therapist_offices/`),

  // --- THERAPIST OTHER ---
  therapistDates: (therapist_id: number) =>
    axios.get<TherapistDates>(`/therapists/${therapist_id}/dates/`),
  therapistAvailableDates: () =>
    axiosAuth.get<AvailableDate[]>(`/my_available_spans/`),
  therapistVisitTypes: () =>
    axiosAuth.get<TherapistVisitType[]>(`/therapist_visit_types/`),
  therapistDateGroups: () => axiosAuth.get<DateGroup[]>(`/date_groups/`),
  therapistBankGroups: (therapist_id: number) =>
    axiosAuth.get<Bank[]>(`/therapist_bank_groups/${therapist_id}`),

  // --- THERAPY ---
  therapy: (therapy: number) => axiosAuth.get<Therapy>(`/therapies/${therapy}`),
  therapies: (therapist?: number, user?: number) =>
    axiosAuth.get<Therapy[]>(
      `/therapies/?therapist=${therapist ? therapist : ""}&user=${
        user ? user : ""
      }`
    ),
  sessionNotes: () => axiosAuth.get<SessionNote>(`/session_notes/`),
  sessionNote: (session_id: number) => axiosAuth.get<SessionNote>(`/session_notes/${session_id}/`),

  // --- SESSION ---
  sessions: (past = false) => axiosAuth.get<Session[]>(`/sessions/`),
  sessionDetails: (id: number) => axiosAuth.get<Session>(`/sessions/${id}/`),
  prescriptions: (id: number) =>
    axios.get<Prescription[]>(`/list_prescriptions/?therapy=${id}`),

  // --- CHALLENGES ---
  challenges: (id: number) =>
    axiosAuth.get<ChallengeSeries>(`/challenges/${id}/`),
  dayChallenges: () => axiosAuth.get(`/day_challenge`),
  challengeShortInfo: () => axiosAuth.get<ChallengeShortInfo[]>("/challenges/"),
  challengePreview: () => axios.get<ChallengeShortInfo[]>("/challenges/"),
  getRelatedChallenges: (challenge_id: number) =>
    axiosAuth.get<ChallengeShortInfo[]>(`/challenges/${challenge_id}/related/`),
  purchasedChallenges: () => axiosAuth.get("/challenges/purchased/"),

  // --- ARTICLES ---
  articles: () => axios.get<Article[]>(`/blog_posts`),
  article: (id: string) => axios.get<Article>(`/blog_posts/${id}`),

  // --- OTHER ---
  moodData: (data_type: string, time_period: number) =>
    axiosAuth.post<MoodScore[]>("/get_data/", { data_type, time_period }),
  messages: (therapy: number, search: string) =>
    axiosAuth.get<Messages>(`/messages/?therapy=${therapy}&search=${search}`),
  patientPackages: () => axiosAuth.get("/my_packages/"),
  dict: () => axios.get<Dictionaries>("/dictionaries/"),
  calmsieBankGroups: () => axiosAuth.get<Bank[]>("/calmsie_bank_groups/"),
  formsAndSectors: (lang: string) =>
    axios.get<LawAndForm>(`/forms_and_sectors/?current_user_language=${lang}`),
  settlements: () => 
    axiosAuth.get<Settlement[]>('/therapist_settlements'),
  preSavedCompanyDetails: () =>
    axiosAuth.get<BillingData>('/pre_saved_company_details')
};
