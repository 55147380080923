import { ModalConfig } from "@/interfaces/modal";
import _Vue, { PluginObject } from "vue";
import Vue from "vue";
import Modal from "./component.vue";

class VueModalStore {
  storeVM: Vue;

  constructor(data = {}) {
    this.storeVM = new Vue({ data });
  }

  get state() {
    return this.storeVM.$data;
  }
}

const VueModal: PluginObject<any> = {
  Store: VueModalStore,
  install(Vue: typeof _Vue, options?: any) {
    Vue.mixin({
      beforeCreate() {
        this.$modal = options.store.state;
      },
    });

    Vue.prototype.$openModal = function(config: ModalConfig) {
      const functions: Record<string, Function> = {};
      if (config.functions) {
        Object.keys(config.functions).forEach((funcName) => {
          if (config.functions) {
            if (config.this)
              functions[funcName] = config.functions[funcName].bind(
                config.this
              );
            else functions[funcName] = config.functions[funcName];
          }
        });
      }
      this.$modal.open = true;
      this.$modal.config = config;
      this.$modal.functions = functions;
    };

    Vue.prototype.$closeModal = function() {
      this.$modal.open = false;
      this.$modal.config = null;
      this.$modal.functions = null;
    };

    Vue.component("modal", Modal);
  },
};

declare module "vue/types/vue" {
  interface Vue {
    $modal: {
      open: boolean;
      config: ModalConfig | null;
      functions: Record<string, Function> | null;
    };
    $openModal: (config: ModalConfig) => void;
    $closeModal: () => void;
  }
}

export default VueModal;
