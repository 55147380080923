import Vue from "vue";
import VueI18n from "vue-i18n";
import { getBrowserLocales } from "./helpers/locales";

Vue.use(VueI18n);

export function getStartingLocale() {
  const browserLocales = getBrowserLocales({ countryCodeOnly: true });
  const lang = localStorage.getItem("locale");
  if (lang) return lang;

  for (const browserLocale of browserLocales) {
    if (
      browserLocale &&
      ["pl", "en", "da", "ru", "uk"].indexOf(browserLocale) > -1
    ) {
      return browserLocale;
    }
  }
  return process.env.VUE_APP_I18N_LOCALE || "en";
}

export function setupI18n() {
  return new VueI18n({
    silentTranslationWarn: true,
  });
}

export async function loadLanguage(i18n: VueI18n, lang: string) {
  let fileName = "";

  if (lang === "en-US" || lang === "en-GB") fileName = "en.json";
  else fileName = `${lang}.json`;

  const messages = await import(`@/locales/${fileName}`);
  i18n.setLocaleMessage(lang, messages.default);
  i18n.locale = lang;
}

export function timeDiff(i18n: VueI18n, miliseconds: number) {
  const formatter = new Intl.RelativeTimeFormat(i18n.locale);
  const seconds = miliseconds / 1000;
  if (Math.abs(seconds) < 60)
    return formatter.format(Math.round(seconds), "second");
  const minutes = seconds / 60;
  if (Math.abs(minutes) < 60)
    return formatter.format(Math.round(minutes), "minute");
  const hours = minutes / 60;
  if (Math.abs(hours) < 24) return formatter.format(Math.round(hours), "hour");
  const days = hours / 24;
  if (Math.abs(days) < 7) return formatter.format(Math.round(days), "day");
  const weeks = days / 7;
  return formatter.format(-Math.round(weeks), "week");
}
