import { RouteConfig } from "vue-router";
const DashboardView = () => import("@/views/dashboard/DashboardView.vue");
const MainTherapyMessenger = () =>
  import("@/views/dashboard/messenger/MainTherapyMessenger.vue");
const TherapyMessenger = () =>
  import("@/views/dashboard/messenger/TherapyMessenger.vue");
const MyVisitsView = () => import("@/views/dashboard/visits/MyVisitsView.vue");
const TherapistVisitsPending = () =>
  import("@/views/dashboard/visits/TherapistVisitsPending.vue");
const TherapistVisitsHistory = () =>
  import("@/views/dashboard/visits/TherapistVisitsHistory.vue");
const PatientVisitsPending = () =>
  import("@/views/dashboard/visits/PatientVisitsPending.vue");
const PatientVisitsHistory = () =>
  import("@/views/dashboard/visits/PatientVisitsHistoric.vue");
const PatientsView = () => import("@/views/dashboard/patient/PatientsView.vue");
const PurchasedChallenges = () =>
  import("@/views/dashboard/challenges/PurchasedChallenges.vue");
const DesktopInfoFillingView = () =>
  import("@/views/dashboard/DesktopInfoFillingView.vue");
const TherapistCalendar = () =>
  import("@/views/therapistCalendar/TherapistCalendar.vue");
const Visit = () => import("@/views/dashboard/Visit.vue");
const InfoPersonalComponent = () =>
  import("@/components/informationFilling/InfoPersonalComponent.vue");
const InfoBackgroundComponent = () =>
  import("@/components/informationFilling/InfoBackgroundComponent.vue");
const InfoBillingDecidePage = () =>
  import("@/components/informationFilling/InfoBillingDecidePage.vue");
const InfoOfficeComponent = () =>
  import("@/components/informationFilling/InfoOfficeComponent.vue");
const InfoAvailabilityComponent = () =>
  import("@/components/informationFilling/InfoAvailabilityComponent.vue");
const Settlements = () => import("@/views/dashboard/SettlementsView.vue");
const ChatBot = () => import("@/views/dashboard/ChatBot.vue");

const routes: RouteConfig[] = [
  {
    path: "",
    component: DashboardView,
    meta: {
      requiresAuth: true,
      requiredInfoFilling: true,
    },
  },
  {
    path: "visits",
    component: MyVisitsView,
    meta: {
      requiresAuth: true,
      requiredInfoFilling: true,
    },
    children: [
      {
        path: "pending",
        component: TherapistVisitsPending,
        meta: {
          requiresAuth: true,
          requiredInfoFilling: true,
        },
      },
      {
        path: "history",
        component: TherapistVisitsHistory,
        meta: {
          requiresAuth: true,
          requiredInfoFilling: true,
        },
      },
    ],
  },
  {
    path: "patient-visits",
    component: MyVisitsView,
    meta: {
      requiresAuth: true,
      requiredInfoFilling: true,
    },
    children: [
      {
        path: "pending",
        component: PatientVisitsPending,
        meta: {
          requiresAuth: true,
          requiredInfoFilling: true,
        },
      },
      {
        path: "history",
        component: PatientVisitsHistory,
        meta: {
          requiresAuth: true,
          requiredInfoFilling: true,
        },
      },
    ],
  },
  {
    path: "challenges/purchased",
    component: PurchasedChallenges,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "messenger/",
    component: MainTherapyMessenger,
    meta: {
      requiresAuth: true,
      requiredInfoFilling: true,
    },
    children: [
      {
        path: ":therapyId",
        component: TherapyMessenger,
        meta: {
          requiresAuth: true,
          requiredInfoFilling: true,
        },
      },
    ],
  },
  {
    path: "chatbot/",
    component: ChatBot,
    meta: {
      requiresAuth: true,
      requiredInfoFilling: true,
    },
  },
  {
    path: "purchased-challenges/",
    name: "purchased-challenges",
    component: PurchasedChallenges,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "calendar-availability/",
    name: "calendar-availability",
    component: TherapistCalendar,
    meta: { requiresAuth: true },
    props: { type: "available" },
  },
  {
    path: "calendar-picked/",
    name: "calendar-picked",
    component: TherapistCalendar,
    meta: { requiresAuth: true },
    props: { type: "picked" },
  },
  {
    path: "myprofile/",
    redirect: "myprofile/personal",
    component: DesktopInfoFillingView,
    meta: {
      requiresAuth: true,
      requiredInfoFilling: false,
    },
    children: [
      {
        path: "personal",
        component: InfoPersonalComponent,
        props: true,
        meta: {
          requiresAuth: true,
          requiredInfoFilling: false,
          stage: 0,
        },
      },
      {
        path: "background",
        component: InfoBackgroundComponent,
        props: true,
        meta: {
          requiresAuth: true,
          requiredInfoFilling: false,
          stage: 1,
        },
      },
      {
        path: "office",
        component: InfoOfficeComponent,
        props: true,
        meta: {
          requiresAuth: true,
          requiredInfoFilling: false,
          stage: 2,
        },
      },
      {
        path: "availability",
        component: InfoAvailabilityComponent,
        props: true,
        meta: {
          requiresAuth: true,
          requiredInfoFilling: false,
          stage: 3,
        },
      },
      {
        path: "billing",
        component: InfoBillingDecidePage,
        props: true,
        meta: {
          requiresAuth: true,
          requiredInfoFilling: false,
          stage: 4,
        },
      },
    ],
  },
  {
    path: "patients/",
    name: "patients",
    component: PatientsView,
    meta: {
      requiresAuth: true,
      requiredInfoFilling: true,
    },
  },
  {
    path: "visit/:sessionId",
    name: "visit",
    component: Visit,
    meta: {
      requiresAuth: true,
      requiredInfoFilling: true,
    },
  },
  {
    path: "settlements",
    component: Settlements,
    meta: {
      requiresAuth: true,
      requiredInfoFilling: true,
    },
  },
];

export default routes;
