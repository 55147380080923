import { AxiosResponse } from "axios";

class DataDownloader<T> {
  public data: T | null;
  public response: AxiosResponse<T> | null;
  public loaded: boolean;
  public error: string;
  public endpoint?: () => Promise<AxiosResponse<T>>;

  constructor(endpoint?: () => Promise<AxiosResponse<T>>) {
    this.data = null;
    this.loaded = false;
    this.error = "";
    this.response = null;
    if (endpoint) {
      this.endpoint = endpoint;
      this.execute();
    }
  }

  private async execute() {
    try {
      if (this.endpoint) {
        const response = await this.endpoint();
        this.response = response;
        this.data = response.data;
        this.loaded = true;
      }
    } catch (err) {
      this.error = err;
      console.log(err);
    }
  }

  public async update(comparison?: (a: T, b: T) => boolean) {
    try {
      if (this.endpoint) {
        this.loaded = false;
        this.error = "";
        const response = await this.endpoint();
        this.response = response;
        if (!this.data) this.data = response.data;
        else if (comparison && !comparison(response.data, this.data))
          this.data = response.data;
        this.loaded = true;
      }
    } catch (err) {
      this.error = err;
      console.log(err);
    }
  }
}

export default DataDownloader;
