import { Visit } from "@/interfaces";
import { SessionNote } from "@/interfaces/therapy";
import { BillingData } from "@/interfaces/payment";
import { axiosAuth } from "./api";

export default {
  visitType: (visitType: Visit.TherapistVisitType) =>
    axiosAuth.put<Visit.VisitType>(
      `/therapist_visit_types/${visitType.id}/`,
      visitType
    ),
  sessionNote: (session_id: number, note: SessionNote) => 
    axiosAuth.put<any>(`/session_notes/${session_id}`, note),
  preSavedCompanyDetails: (billingData: BillingData) =>
    axiosAuth.put<void>('/pre_saved_company_details/', billingData)
};
