
import Vue from "@/interfaces/vue";
import { Component } from "vue-property-decorator";

const Booking = () => import("@/components/Booking.vue");
const CalendarModal = () =>
  import("@/components/therapistCalendar/CalendarModal.vue");
const CalendarBookedModal = () =>
  import("@/components/therapistCalendar/CalendarBookedModal.vue");
const InfoFillingModal = () =>
  import("@/components/informationFilling/InfoFillingModal.vue");
const SavedSuccessfullyModal = () =>
  import("@/components/informationFilling/SavedSuccessfullyModal.vue");
const Filters = () => import("@/components/Filters.vue");
const PurchaseChallenge = () =>
  import("@/components/challenges/PurchaseChallenge.vue");
const PaymentConfirmation = () =>
  import("@/components/challenges/ChallengePaymentConfirmation.vue");
const VisitCancelTherapist = () =>
  import("@/components/visits/VisitCancelTherapist.vue");
const VisitCancelPatient = () =>
  import("@/components/visits/VisitCancelPatient.vue");
const AfterSignUpModal = () => import("@/components/AfterSignUpModal.vue");

@Component({
  components: {
    Booking,
    InfoFillingModal,
    SavedSuccessfullyModal,
    CalendarModal,
    CalendarBookedModal,
    Filters,
    PurchaseChallenge,
    VisitCancelTherapist,
    VisitCancelPatient,
    AfterSignUpModal,
    PaymentConfirmation
  },
})
export default class Modal extends Vue {
  get config() {
    return this.$modal.config;
  }

  get functions() {
    return this.$modal.functions;
  }

  get show() {
    return this.$modal.open;
  }

  run(func: string, args: any[]) {
    if (this.functions) {
      this.functions[func](...args);
    }
  }
}
